<style lang="scss" scoped>
.page-merchant-statistics {
    .statistics-select {
        width: 120px;
    }
}
</style>

<template>
    <div class="page-merchant-statistics">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-radio-group class="mrgr5 mrgb5" v-model="value" size="medium" @change="changeValue">
                        <el-radio-button label="1">最近一周</el-radio-button>
                        <el-radio-button label="2">最近一个月</el-radio-button>
                        <el-radio-button label="3">最近三个月</el-radio-button>
                        <el-radio-button label="4">自定义</el-radio-button>
                    </el-radio-group>
                    <el-date-picker class="mrgr5 mrgb5" v-model="dateRangeTong" type="datetimerange"
                        start-placeholder="(统计)开始日期" end-placeholder="(统计)结束日期" size="medium" @change="timeVoiTong"
                        v-if="value == '4'"></el-date-picker>
                </div>
                <div class="fr">
                    <el-date-picker class="mrgr5 mrgb5" v-model="dateRange" type="datetimerange"
                        start-placeholder="(开店)开始日期" end-placeholder="(开店)结束日期" size="medium"
                        @change="timeVoi"></el-date-picker>
                    <el-select class="statistics-select mrgr5 mrgb5" v-model="filter.provinceCode" placeholder="请选择省"
                        clearable @change="getDataList()" size="medium">
                        <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="statistics-select mrgr5 mrgb5" v-model="filter.cityCode" placeholder="请选择市" clearable
                        @change="getDataList()" size="medium">
                        <el-option v-for="item in cityOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="statistics-select mrgr5 mrgb5" v-model="filter.areaCode" placeholder="请选择区/县"
                        clearable @change="getDataList()" size="medium">
                        <el-option v-for="item in countyOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select style="width: 150px;" class="mrgr5 mrgb5" v-model="filter.isActive" placeholder="(地图)启用状态"
                        clearable @change="getDataList()" size="medium">
                        <el-option :value="true" label="启用中"></el-option>
                        <el-option :value="false" label="已禁用"></el-option>
                    </el-select>
                    <el-select class="statistics-select mrgr5 mrgb5" v-model="filter.agentId" filterable remote
                        reserve-keyword placeholder="代理(输入搜索)" :remote-method="renderAgentOptions" clearable
                        @change="getDataList()" size="medium">
                        <el-option v-for="item in agentItems" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="店铺名" clearable v-model="filter.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" @click="_search()" size="medium"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-tabs v-model="activeName" @tab-click="handleClick" stretch type="border-card">
                <el-tab-pane label="卡片" name="first">
                    <el-table :data="tableData" row-key="id" @sort-change="sortChange">
                        <el-table-column prop="id" label="ID" sortable="custom"></el-table-column>
                        <el-table-column prop="name" label="店铺" sortable="custom"></el-table-column>
                        <el-table-column prop="turnover" label="营业额" sortable="custom"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="图表" name="second">
                    <div v-for="(item, index) in tableData" :key="index">
                        <div>ID:{{ item.id }}、{{ item.name }}</div>
                        <el-progress :percentage="item.turnover / percentage * 100" :show-text="false"></el-progress>
                        <div style="text-align: end;">￥{{ item.turnover }}</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as funMerchant from "@/api/merchant/index"
import * as funAgent from "@/api/agent/index"
import * as appRegion from "@/utils/app_region"
import moment from "moment"
export default {
    name: "pageMerchantStatistics",
    data() {
        return {
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                startStatisticsDate: null, //统计开始时间
                endStatisticsDate: null, //统计结束时间
                agentId: "", //代理ID
                status: null, //店铺状态  number 0为营业中，-1已关闭
                startDate: null,
                endDate: null,
                provinceCode: "", //省
                cityCode: "", //市
                areaCode: "", //区
                categoryId: "",
                isActive: null,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize,
                sortField: null,
                sortDirection: 0
            },
            dateRange: [], //开店时间改变
            dateRangeTong: [], //统计时间改变
            provinceOptions: [], //省
            cityOptions: [], //市
            countyOptions: [], //区
            agentItems: [],
            value: "1",
            activeName: "first", //选择卡片或图表
            percentage: ""
        }
    },
    watch: {
        "filter.provinceCode"(provinceCode) {
            this.filter.cityCode = ""
            this.filter.areaCode = ""
            this.cityOptions = appRegion.getCitys(provinceCode)
            this.countyOptions = []
        },
        "filter.cityCode"(cityCode) {
            this.filter.areaCode = ""
            this.countyOptions = appRegion.getCountys(cityCode)
        },
        "value"(value) {
            let timeArr = []
            if (value == "2") {
                // 近1月
                timeArr = this.setPickerTime(null, 1, "months")
            } else if (value == "3") {
                // 近3月
                timeArr = this.setPickerTime(null, 3, "months")
            } else {
                // 近一周
                timeArr = this.setPickerTime(null, 1, "weeks")
            }
            this.setQueryTime(timeArr)
        }
    },
    created() {
        let timeArr = this.setPickerTime(null, 1, "weeks")
        this.setQueryTime(timeArr)
        this.getDataList()
        this.provinceOptions = appRegion.getProvinces()
    },
    methods: {
        setQueryTime(timeArr) {
            this.filter.startStatisticsDate = timeArr[0] || ""
            this.filter.endStatisticsDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funMerchant.QueryTurnoverStatistics(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                    this.percentage = this.tableData[0].turnover + 100
                } else {
                    this.tableData = []
                    this.percentage = 0
                }
            })
        },
        // 开店日期改变
        timeVoi(value) {
            if (value != null && value.length == 2) {
                let value0 = moment(value[0])
                let value1 = moment(value[1])
                this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")
                if (value1.format("HH") == "00" &&
                    value1.format("mm") == "00" &&
                    value1.format("ss") == "00") {
                    this.filter.endDate = value1.format("YYYY-MM-DD 23:59:59")
                    this.dateRange = [
                        (this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endDate = value1.format("YYYY-MM-DD 23:59:59")),
                    ]
                } else {
                    this.filter.endDate = value1.format("YYYY-MM-DD HH:mm:ss")
                    this.dateRange = [
                        (this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endDate = value1.format("YYYY-MM-DD HH:mm:ss")),
                    ]
                }
                this.getDataList()
            } else {
                this.filter.startDate = ""
                this.filter.endDate = ""
                this.getDataList()
            }
        },
        //统计日期改变
        timeVoiTong(value) {
            if (value != null && value.length == 2) {
                let value0 = moment(value[0])
                let value1 = moment(value[1])
                this.filter.startStatisticsDate = value0.format("YYYY-MM-DD HH:mm:ss")
                if (value1.format("HH") == "00" &&
                    value1.format("mm") == "00" &&
                    value1.format("ss") == "00") {
                    this.filter.endStatisticsDate = value1.format("YYYY-MM-DD 23:59:59")
                    this.dateRangeTong = [
                        (this.filter.startStatisticsDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endStatisticsDate = value1.format("YYYY-MM-DD 23:59:59"))
                    ]
                } else {
                    this.filter.endStatisticsDate = value1.format("YYYY-MM-DD HH:mm:ss")
                    this.dateRangeTong = [
                        (this.filter.startStatisticsDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endStatisticsDate = value1.format("YYYY-MM-DD HH:mm:ss"))
                    ]
                }
                this.getDataList()
            } else {
                this.value = "1"
                this.getDataList()
            }
        },
        async renderAgentOptions(query) {
            await funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        //点击搜索
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
        async sortChange(e) {
            //排序
            if (e.order) {
                this.filter.sortField = e.prop;
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this.getDataList()
        },
        // 统计时间改变
        changeValue() {
            this.getDataList()
        },
        handleClick() { }
    }
}
</script>